// Images file for 'qualifiedwindowsurvey'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Favicon from './assets/favicon.ico';
import Checked from './assets/checked.png';
import Unselected from '../../../../src/images/unselected-circle-white.png';
import Selected from '../../../../src/images/selected-circle-white.png';
import Dropdown from '../../../../src/images/dropdown_carat.png';
// window logos
import Arched from './assets/windowImages/arched.png';
import DoubleHung from './assets/windowImages/double-hung.png';
import Fixed from './assets/windowImages/fixed.png';
import Sliding from './assets/windowImages/sliding.png';
import Casement from './assets/windowImages/casement.png';
import Skylight from './assets/windowImages/skylight.png';

export default {
  Favicon,
  Dropdown,
  Checked,
  Unselected,
  Arched,
  DoubleHung,
  Fixed,
  Sliding,
  Casement,
  Skylight,
  Selected,
};
